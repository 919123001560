@import '../theme-bootstrap';

.loading-text-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  span {
    filter: blur(0px);
    color: black;
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

.loading-effect-active {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: white url(/media/images/ajax-loading.gif) no-repeat center center;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=00);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
  &.fade-in {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  }
  &:before {
    content: '';
    display: inline-block;
    margin-right: -0.25em;
    height: 100%;
    vertical-align: middle;
  }
}
